import React, { useState } from "react";
import PaymentLink from "./containers/PaymentLink";
import { Routes, Route, BrowserRouter } from "react-router-dom";

const App = () => {

  return (
    <div>
      
      <BrowserRouter>
        <Routes>
          <Route exact path="/payment-request/:id" element={<PaymentLink />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
